<template>
  <v-skeleton-loader :loading="status.loading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="licenses-show">
      <template v-if="objectId">
        <div>{{ thingy.name }}</div>
        <div>{{ thingy.description }}</div>

        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <v-btn
            v-bind="propsButtonAction"
            :to="{ name: 'licenses-edit', params: { id: objectId } }"
            data-cy="button-edit"
            >{{ $t("common.edit") }}</v-btn
          >
        </div>
      </template>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "LicensesShow",
  mixins: [ComponentStatus],
  components: {},
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
  }),
  computed: {
    // 2) When the thingy id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the thingy data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    thingy() {
      return this.$store.getters["licenses/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the thingy id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        this.setStatusReading();
        this.$store
          .dispatch("licenses/readById", this.objectId)
          .then((/* result */) => {
            this.setStatusReadSuccess();
          })
          .catch((/* error */) => {
            this.setStatusReadError();
            this.$store.commit("status/showError");
          });
      },
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
